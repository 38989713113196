import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// Layout component
import Layout from '../components/layouts/Layout';
import BannerPicture from '../components/BannerPicture';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import HeaderMap from '../components/HeaderMap';
import CustomersLogos from '../components/CustomersLogos';
import SectionOnlyFooter from '../components/SectionOnlyFooter';

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;

const VideoIframe = styled.div`
    position: relative;
    padding-bottom: 53%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    border-radius: 10px;
    margin: 7% 0%;
`;

const IframeVideo = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`;

const KioskMockup = styled(GatsbyImage)`
    position: relative;
    z-index:-1;
    top:-60px;
    left:0px;
    margin: 0 auto;
    @media (max-width: 812px) {
      max-width: 114%;
      left:-4px;
    }

    @media (min-width: 320px) and (max-width: 568px) {
        left:-1px;
    }
`;

const BgDarkBlue = styled.div`
    position: absolute;
    left: 0;
    z-index: -10000;
    background-color: #0c499b;
    width: 100%;
    height: 39%;
`;

const LivemapIframePhone = styled.div`
    position: relative;
    display: flex;
    width: 320px;
    height: 568px;
    border: none;
    margin: 0 auto;
    box-shadow: 3px 3px 18px 3px rgba(99,99,99,.16863);
    max-width:100% !important;
    min-height:350px;
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('wemapKioskPage');

    const icons = [{
        icon: 'icon-mobile-devices',
        text: t('TITLE_ICON_NO_DEVELOPMENT')
    }, {
        icon: 'icon-layers2',
        text: t('TITLE_ICON_MULTIPLE_SOURCES')
    },
    {
        icon: 'icon-round-brush',
        text: t('TITLE_ICON_CREATE_CUSTOM_MAPS')
    }];

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_KIOSK')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_KIOSK')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <CustomersLogos title={t('THEY_USE_KIOSK')}
                        size='large'
                        customersLogos={[
                            {image: '/images/industries/culture/musee_du_louvre_logo.png'},
                            {image: '/images/products/wemap-navigation/logo_jc_decaux.png'},
                            {image: '/images/products/indoor/logo_ratp.svg'},
                            {image: '/images/industries/havre-logo.png'}
                        ]}
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_CONTENT')}
                        pictureRight={data.kiosk.childImageSharp}
                        altDescription='Outdoor interactive screen with Wemap map'
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map2',
                                text: t('DESCRIPTION_MAP_RICH_CONTENT')
                            },
                            {
                                icon: 'icon-checkmark',
                                text: t('DESCRIPTION_SEARCH_FILTERS')
                            },
                            {
                                icon: 'icon-user-plus',
                                text: t('DESCRIPTION_SERVICES')
                            }
                        ]}
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons
                            title={t('TITLE_MAPS_TECHNOLOGY')}
                            text={t('DESCRIPTION_USEFUL_INFORMATION')}
                            titleButton={t('BUTTON_TALK_ABOUT_PROJECT')}
                            icons={icons}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <VideoIframe>
                        <IframeVideo title="youtube"
                            width="101%"
                            height="100%"
                            src="https://www.youtube.com/embed/rApA6NqTPAM"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                        </IframeVideo>
                    </VideoIframe>

                    <BgDarkBlue></BgDarkBlue>
                    <HeaderMap customerCicleImageUrl='/images/products/wemap-kiosk/logo-ratp.png'
                        alt= 'RATP logo'
                        titleCaseStudy={t('Case Study')}
                        title={t('TITLE_RATP')}
                        by={t('TEXT_BY')}
                        text={t('TITLE_DESCRIPTION_RATP')}
                        textPointInfos={t('TITLE_POINTS_RATP')}
                        textViewsInfos={t('TITLE_VIEWS_RATP')}
                        descriptionCustomer={t('TITLE_INFO_RATP')}
                        infosRight1={t('TITLE_DESCRIPTION_RIGHT_AF_0')}
                        infosRight2={t('TITLE_DESCRIPTION_RIGHT_AF_1')}
                        infosRight3={t('TITLE_DESCRIPTION_RIGHT_AF_2')} />


                    <LivemapIframePhone>
                        <iframe title="livemap"
                            width="70%"
                            height="70%"
                            src="https://ratp.getwemap.com/kiosk.html?emmid=15183&kioskid=gambetta#/aroundme"
                            allowfullscreen
                            style={{
                                borderRadius: '0px',
                                position: 'relative',
                                bottom: '6px',
                                left: '65px'
                            }}
                            frameborder="0"></iframe>

                        <KioskMockup imgStyle={{ objectFit: 'contain' }}
                            image={data.kioskMockup.childImageSharp.gatsbyImageData}
                            alt="TEXTE"
                            style={{position: 'absolute'}}
                        />
                    </LivemapIframePhone>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_CONTENT_LEVERAGE')}
                        pictureRight={data.webApps.childImageSharp}
                        pictureRightWidth="48%"
                        altDescription='mobile with connexion screen to media'
                        titleButton={t('TITLE_BUTTON_DISCOVER_WEMAP')}
                        urlFooter='/products/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map2',
                                text: t('DESCRIPTION_CONTENT_EASE')
                            },
                            {
                                icon: 'icon-checkmark',
                                text: t('DESCRIPTION_CREATE_DEPLOY')
                            },
                            {
                                icon: 'icon-user-plus',
                                text: t('DESCRIPTION_ENRICH_MAP')
                            },
                            {
                                icon: 'icon-coin-dollar',
                                text: t('DESCRIPTION_MONETIZE')
                            }
                        ]}
                    />

                    <BannerPicture
                        backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with computer and mobile view of Wemap map'
                        titleBannerPicture={t('TITLE_BANNER_CASE_STUDIES')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/' />

                </div>
            </Layout>
        </React.Fragment>
    );
};
IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  kiosk: file(relativePath: {eq: "products/wemap-kiosk/wemap_kiosk.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 260, layout: FIXED)
    }
  }
  webApps: file(relativePath: {eq: "products/wemap-web/wemap_web_apps.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 420, layout: FIXED)
    }
  }
  kioskMockup: file(
    relativePath: {eq: "products/wemap-kiosk/kiosk-with-background-v1.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 350, layout: FIXED)
    }
  }
  banner: file(relativePath: {eq: "products/wemap-kiosk/banner_wemap_kiosk.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
}
`;
